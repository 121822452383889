<template>
 <div class="wrapper">
    <div class="header-img">
      <input type="checkbox" id="input" hidden />
        <div class="box" id="jjjj">
          <div class="row justify-content-center">
            <label for="input" id="im">
              <div class="container registro">
                <div class="row justify-content-center">
                  <div class="col">
                    <form>
                      <button type="button" @click="returnHome()" class="btn-close" aria-label="Close">X</button>
                      <h1 class="text text-center mt-5">Formulario de Registro<br>
                      
                      </h1>
                      <div class="form-group mt-5">
                        <b-form inline>
                          <input class="form-control form-control-lg mt-3 mr-3" type="text" v-model="nombre" required placeholder="Nombre" autocomplete="off">             
                          <input class="form-control form-control-lg mt-3" type="text" v-model="apellidos" required placeholder="Apellidos" autocomplete="off">  
                        </b-form>
                        <b-form inline>
                          <input class="form-control form-control-lg mt-3 mr-3" type="text" v-model="dni" required placeholder="D.N.I" autocomplete="off">
                          <input class="form-control form-control-lg mt-3" type="text" v-model="telefono" required placeholder="Teléfono" autocomplete="off">
                        </b-form>
                        <b-form inline>  
                          <input class="form-control form-control-lg mt-3 mr-3" type="email" v-model="email" required placeholder="Email" autocomplete="off"> 
                          <input class="form-control form-control-lg mt-3" type="password" v-model="contrasenna" required placeholder="Contraseña" autocomplete="off">
                        </b-form>
                          <input class="form-control form-control-lg mt-3" type="text" v-model="direccion" required placeholder="Dirección" autocomplete="off">
                        <b-form inline>
                          <input class="form-control form-control-lg mt-3 mr-3" type="text" v-model="ciudad" required placeholder="Ciudad" autocomplete="off">    
                          <input class="form-control form-control-lg mt-3" type="text" v-model="provincia" required placeholder="Provincia" autocomplete="off"> 
                        </b-form>
                        <b-form inline>  
                          <input class="form-control form-control-lg mt-3 mr-3" type="text" v-model="codigo_postal" required placeholder="Código Postal" autocomplete="off"> 
                          <input class="form-control form-control-lg mt-3" type="text" v-model="pais" required placeholder="Pais" autocomplete="off"> 
                        </b-form>
                        </div>
                            
                        <p v-if="isInvalid" class="error">Parece que existe un error en tu email o en tu contraseña, por favor, inténtalo de nuevo.</p>
                        <button type="submit" class="btn btn-dark btn-lg btn-block mt-5 mb-5" @click="validatingDataRegister()">Registra tu cuenta</button>
                        <p class="msg mb-3 text-center">¿Ya tienes una cuenta?
                          <router-link to="/login-clientes">Accede aqui</router-link>
                        </p>
                      </form>
                  </div>
                </div>
              </div>     
            </label>
          </div>
          <div class="container-fluid login_footer">
          <div class="row justify-content-center">
            <div class="col">
              <figure>
                <p class="col justify-content-left marca">
                  <a class="external_link mr-4" target="_blank" href="http://www.2kbo.com/cluf.html">Contrato de Licencia de Usuario Final</a>
                  <a class="external_link mr-4" target="_blank" href="http://www.2kbo.com/politicaprivacidad.html">Política de Privacidad</a>
                  <a class="external_link mr-4" target="_blank" href="http://www.2kbo.com/copyrightylicencia.html">Copyright y Licencia de software</a>
                  <a class="external_link mr-4" target="_blank" href="http://www.2kbo.com/licenciasdeterceros.html">Licencias de terceros</a>
                  <a class="external_link" target="_blank" href="http://www.2kbo.com/requerimientostecnicos.html">Requerimientos técnicos</a>
                </p>
                <p class="marca_kbo">© KBO 2021. Marca KBO. Versión 1.0</p>
              </figure>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//IMPORTAMOS AXIOS Y LA LIBRERIA QUE SACA UNA VENTANA EMERGENTE 
//CONFIGURABLE AL HACER CLICK EN UN BOTON
import axios from 'axios';

export default {
    name: 'RegistroView',
    //ENVIAMOS DATOS
    data() {
    return {
      imagen: '',
      nombre: '',
      apellidos: '',
      dni: '',
      email: '',
      contrasenna: '',
      direccion: '',
      ciudad: '',
      provincia: '',
      codigo_postal: '',
      pais: '',
      telefono: '',
      isInvalid: '',
      createUser: false,
      errorMsg: false
      }
    },
    methods: {
        returnHome() {
        this.$router.push('/')
        },
        //FUNCION QUE COMPRUEBA QUE TODOS LOS CAMPOS REQUERIDOS EXISTEN    
        validatingDataRegister() {
                if(
                this.imagen === '' ||
                this.nombre === ''||
                this.apellidos === '' ||
                this.dni === '' ||
                this.email === ''||
                this.contrasenna === ''||
                this.direccion === ''||
                this.ciudad === ''||
                this.provincia === ''||
                this.codigo_postal === ''||
                this.pais === ''||
                this.telefono === '') { //comprobamos que no hay campos vacios
                    this.errorMsg = true
                    this.createUser = false;
                Swa1.fire({
                    title: 'All fields required!',
                    icon: 'error',
                    confirmButtonText: 'Ok',
                    background: 'black',
                    confirmButtonColor: 'transparent',
                        onClose: () => {
                        this.addNewUser()
                        }
                })
                }  else {
                    this.errorMsg = false
                    this.createUser = true;
                    this.addNewUser()
                Swa1.fire({
                    title: 'Successfully Registered! An email was sent to your inbox!',
                    text: 'Do you want to go to the Log In page?',
                    icon: 'success',
                    confirmButtonText: 'Yes, send me there!',
                    background: 'black',
                    confirmButtonColor: 'transparent',
                    showCancelButton: true,
                    CancelButtonColor: 'transparent',
                        onClose: () => {
                        location.assign("http://localhost:8080/#/login")
                        }
                    }).then((result) => {
                    if (!result.value) {
                        Swa1.fire(
                        setTimeout( () => {
                        location.assign("http://localhost:8080/#/registro")
                        location.reload()
                        }, 3000)
                        )}
                    })
                }
            },
        //FUNCION PARA AÑADIR USUARIOS
        addNewUser() {
            if(this.createUser === true) {
                //LLAMADA DE AXIOS AL ENDPOINT
                axios.post('http://localhost:3000/usuarios/add', {
                imagen: this.imagen,
                nombre: this.nombre,
                apellidos: this.apellidos,
                dni: this.dni,
                email: this.email,
                contrasenna: this.contrasenna,
                direccion: this.direccion,
                ciudad: this.ciudad,
                provincia: this.provincia,
                codigo_postal: this.codigo_postal,
                pais: this.pais,
                telefono: this.telefono
                })
                .then((response) => {
                    console.log(response);
                })
                .catch((error) => {
                    console.log(error)
                })
                this.createUser = false;
                this.imagen = '',
                this.nombre = '',
                this.apellidos = '',
                this.dni = '',
                this.email = '',
                this.contrasenna = '',
                this.direccion = '',
                this.ciudad = '',
                this.provincia = '',
                this.codigo_postal = '',
                this.pais = '',
                this.telefono = ''//Asi vacia los campos una vez enviada la informacion para crear nuevo cliente
            } else {
                console.log('Yo no deberia estar aqui');
            }
        }
    }
}

</script>

<style lang="scss" src="../scss/estilos.scss" scoped></style>
  


   